<template>
  <div
    v-if="value"
    class="
      tw-modal
      tw-z-10
      tw-fade
      tw-fixed
      tw-top-0
      tw-left-0
      tw-w-full
      tw-h-full
      tw-outline-none
      tw-overflow-x-hidden
      tw-overflow-y-auto
      tw-bg-opacity-50
      tw-bg-black
    "
    tabindex="-1"
    aria-labelledby="exampleModalCenteredScrollable"
    aria-modal="true"
    role="dialog"
  >
    <div
      class="
        tw-modal-dialog
        tw-modal-dialog-centered
        tw-modal-dialog-scrollable
        tw-relative
        tw-w-auto
        tw-pointer-events-none
        tw-max-w-[30rem]
        tw-m-auto
        tw-transform
        tw--translate-y-1/2
        tw-top-1/2
      "
    >
      <div
        class="
          tw-modal-content
          tw-border-none
          tw-shadow-lg
          tw-relative
          tw-flex
          tw-flex-col
          tw-w-full
          tw-pointer-events-auto
          tw-bg-white
          tw-bg-clip-padding
          tw-rounded-md
          tw-outline-none
          tw-text-current
        "
      >
        <div
          class="
            tw-modal-header
            tw-flex
            tw-flex-shrink-0
            tw-items-center
            tw-justify-between
            tw-p-4
            tw-border-b
            tw-border-gray-200
            tw-rounded-t-md
          "
        >
          <h5
            class="tw-text-xl tw-font-medium tw-leading-normal tw-text-gray-800"
            id="exampleModalCenteredScrollableLabel"
          >
            Assign agent
          </h5>
        </div>
        <div class="filter-box tw-w-full tw-h-auto tw-flex tw-px-4 tw-pt-4">
          <div class="tw-relative search tw-w-full tw-h-full">
            <input
              class="
                tw-flex
                tw-w-full
                tw-h-8
                tw-px-2
                tw-pl-8
                tw-border
                tw-border-slate-200
                tw-rounded-lg
                tw-text-sm
                tw-text-slate-600
              "
              type="text"
              placeholder="Search..."
              v-model.trim="filterInput"
            />
            <svg
              class="
                filter-icom
                tw-flex
                tw-absolute
                tw-w-4
                tw-h-4
                tw-top-2
                tw-left-2
                tw-fill-slate-300
              "
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"
              />
            </svg>
          </div>
        </div>
        <div
          class="
            modal-body
            tw-p-4 tw-h-[30rem] tw-flex tw-flex-col tw-overflow-auto
          "
        >
          <AgenCard
            v-for="agent in filteredAgents"
            :key="agent.AgentID"
            :agent="agent"
            @select-agent="onAssignAgent(agent)"
          />
        </div>
        <div
          class="
            modal-footer
            tw-flex
            tw-flex-shrink-0
            tw-flex-wrap
            tw-items-center
            tw-justify-end
            tw-p-4
            tw-border-t
            tw-border-gray-200
            tw-rounded-b-md
          "
        >
          <button
            type="button"
            class="
              tw-inline-block
              tw-px-6
              tw-py-2.5
              tw-bg-red-400
              tw-text-white
              tw-font-medium
              tw-text-xs
              tw-leading-tight
              tw-uppercase
              tw-rounded
              tw-shadow-md
              hover:tw-bg-red-500 hover:tw-shadow-lg
              focus:tw-bg-red-700
              focus:tw-shadow-lg
              focus:tw-outline-none
              focus:tw-ring-0
              active:tw-bg-red-800 active:tw-shadow-lg
              tw-transition tw-duration-150 tw-ease-in-out
            "
            data-bs-dismiss="modal"
            @click="$emit('input', false)"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AssignAgentDialog",
  props: ["value", "channel"],
  components: {
    AgenCard: () => import("./AgentCard.vue"),
  },
  data() {
    return {
      filterInput: "",
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      agents: "chat/agents",
      user: "userInfo",
    }),
    filteredAgents() {
      // return this.agents;
      let list = this.agents.filter((a) => a.AgentID !== this.user.userCode);
      if (this.filterInput) {
        return list.filter((agent) => {
          return (
            agent.AgentID.search(this.filterInput) !== -1 ||
            agent.Name.search(this.filterInput) !== -1
          );
        });
      }
      return list;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.$store.dispatch("chat/listAgent");
        }
      },
    },
  },
  methods: {
    onAssignAgent(agent) {
      console.log("asign to :", agent);
      try {
        //TODO assign agent
        const payload = {
          agentID: agent.AgentID,
          agentName: agent.Name,
        };
        this.$store.dispatch("chat/assignAgent", payload);
        this.$emit("input", false);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0px !important;
}
</style>
